import React from "react";
import styled from "styled-components";
import { navigateUrl } from "../../utils";

interface DropStyledProps {
    increment: number;
    randoFiver: number;
    randoHundo: number;
    children: JSX.Element;
}

const DropStyled = styled.div.attrs((props: DropStyledProps) => ({
    className: 'drop',
    style: {
        left: `${props.increment}%`,
        bottom: `${props.randoFiver + props.randoFiver - 1 + 100}%`,
        animationDelay: `0.${props.randoHundo}s`,
        animationDuration: `0.5${props.randoHundo}s`,
    }
}))<DropStyledProps>``;

const Drop = (props: DropStyledProps): JSX.Element => {
    return <DropStyled increment={props.increment} randoFiver={props.randoFiver} randoHundo={props.randoHundo}>{props.children}</DropStyled>;
};

interface StemStyledProps {
    randoHundo: number;
    children: JSX.Element;
}

const StemStyled = styled.div.attrs((props: StemStyledProps) => ({
    className: 'stem',
    style: {
        animationDelay: `0.${props.randoHundo}s`,
        animationDuration: `0.5${props.randoHundo}s`,
    }
}))<StemStyledProps>``;

const Stem = (props: StemStyledProps): JSX.Element => {
    return <StemStyled randoHundo={props.randoHundo}>{props.children}</StemStyled>
}

interface SplatStyledProps {
    randoHundo: number;
}

const SplatStyled = styled.div.attrs((props: SplatStyledProps) => ({
    className: 'splat',
    style: {
        animationDelay: `0.${props.randoHundo}s`,
        animationDuration: `0.5${props.randoHundo}s`
    }
}))<SplatStyledProps>``;

const Splat = (props: SplatStyledProps): JSX.Element => {
    return <SplatStyled randoHundo={props.randoHundo}/>
}

export interface TrenchesProps {
    onHoverEnter: () => void,
    onHoverExit: () => void,
    isHovering: boolean,
    numDrops: number,
}

export const Trenches = (props: TrenchesProps): JSX.Element => {
    return (
        <div className='flex justify-center self-center trenches h-full' data-testid="trenches-container" onMouseEnter={props.onHoverEnter} onMouseLeave={props.onHoverExit} onClick={() => navigateUrl('https://trenches.otfgaming.com')}>
            <div className="flex justify-center">
                {(
                    Array.from(Array(props.numDrops).keys()).map((i, k) => {

                        //couple random numbers to use for various randomizations
                        //random number between 98 and 1
                        const randoHundo = (Math.floor(Math.random() * (98 - 1 + 1) + 1));

                        //random number between 5 and 2
                        const randoFiver = (Math.floor(Math.random() * (5 - 2 + 1) + 2));

                        return (
                            <Drop key={k} increment={i} randoFiver={randoFiver} randoHundo={randoHundo}>
                                <Stem randoHundo={randoHundo}>
                                    <Splat randoHundo={randoHundo}/>
                                </Stem>
                            </Drop>
                        )
                    })
                )}
            </div>
        </div>
    );
}