import React from "react";
import { NavLink } from "react-router-dom";

export const OTF = (): JSX.Element => {
    return (
        <div>
            <NavLink to="/otfgaming">
            <div className='flex justify-center self-center otfgaming h-full' data-testid="otf-container">
                &nbsp;
            </div>
            </NavLink>
        </div>
    );
}