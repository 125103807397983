import React, { useState } from 'react';
import './App.css';
import { OTF } from './components/otf/OTF';
import { Trenches } from './components/trenches/Trenches';

export const App = (): JSX.Element => {
  const [isTrenchesHovering, setTrenchesHover] = useState(false);

  return (
    <div className='max-h-screen max-w-screen overflow-hidden otf-container'>
      <div className='opacity-20 absolute m-auto left-0 right-0 w-1 h-screen hidden sm:block'>&nbsp;</div>
      <div className='opacity-20 absolute my-auto top-0 bottom-0 h-1 w-screen sm:hidden'>&nbsp;</div>
      <div className='grid-cols-2 min-h-screen min-w-screen hidden sm:grid gap-0 auto-rows-fr'>
        <Trenches numDrops={100} isHovering={isTrenchesHovering} onHoverEnter={() => setTrenchesHover(true)} onHoverExit={() => setTrenchesHover(false)}/>
        <OTF/>
      </div>
      <div className='grid-rows-2 min-h-screen min-w-screen grid sm:hidden gap-1 auto-rows-fr'>
        <Trenches numDrops={100} isHovering={isTrenchesHovering} onHoverEnter={() => setTrenchesHover(true)} onHoverExit={() => setTrenchesHover(false)}/>
        <OTF/>
      </div>
    </div>
  );
}
